import i18next from 'i18next';

i18next.addResources('es', 'WORKS_ITINERARIES', {
  PERU_FORMATION_TITLE: 'Formación Cáritas Perú.',
  PERU_FORMATION_CARITAS_COMMUNICATION_TITLE:
    'Aportes para la comunicación en Cáritas',
  PERU_FORMATION_CARITAS_COMMUNICATION_DESCRIPTION:
    'Serie de encuentros y aportes temáticos sobre la comunicación en Cáritas, junto con el diseño gráfico y la puesta en imprenta del material.\nFormato: 20,5 x 29,5 cm, tapa 4/1 e interiores color 2/2, anillado de alambre doble, de 76 páginas. Impreso en la Argentina.',
  PERU_FORMATION_GROW_TITLE: 'Crecer desde el trabajo',
  PERU_FORMATION_GROW_DESCRIPTION:
    'Itinerario formativo (propuesta didáctica y fichas de trabajo) diseñado para la capacitación de adultos en clave de emprendimientos cooperativos. Desarrollado en la Escuela de Oficios de Cáritas, Zárate (Buenos Aires).\nFormato: 5 encuentros presenciales y fichas de trabajo.',
  PERU_FORMATION_REFERENTS_ITINERARY_TITLE:
    'Itinerario de formación de referentes de la comunicación',
  PERU_FORMATION_REFERENTS_ITINERARY_DESCRIPTION:
    'Cáritas Patagonia Comahue (Argentina).\nProceso anual de capacitación semi presencial (2010) con acompañamiento a distancia, para referentes voluntarios de la comunicación institucional en la Región Patagonia Comahue.',
  PERU_FORMATION_VOLUNTARIES_ITINERARY_TITLE:
    'Itinerario de formación de voluntariado social',
  PERU_FORMATION_VOLUNTARIES_ITINERARY_DESCRIPTION:
    'Cáritas Zárate Campana (Argentina).\nItinerarios de capacitación presencial (2003–2005) para voluntariado social de las Cáritas locales.',
});

i18next.addResources('en', 'WORKS_ITINERARIES', {
  PERU_FORMATION_TITLE: 'Formación Cáritas Perú.',
  PERU_FORMATION_CARITAS_COMMUNICATION_TITLE:
    'Aportes para la comunicación en Cáritas',
  PERU_FORMATION_CARITAS_COMMUNICATION_DESCRIPTION:
    'Serie de encuentros y aportes temáticos sobre la comunicación en Cáritas, junto con el diseño gráfico y la puesta en imprenta del material.\nFormato: 20,5 x 29,5 cm, tapa 4/1 e interiores color 2/2, anillado de alambre doble, de 76 páginas. Impreso en la Argentina.',
  PERU_FORMATION_GROW_TITLE: 'Crecer desde el trabajo',
  PERU_FORMATION_GROW_DESCRIPTION:
    'Itinerario formativo (propuesta didáctica y fichas de trabajo) diseñado para la capacitación de adultos en clave de emprendimientos cooperativos. Desarrollado en la Escuela de Oficios de Cáritas, Zárate (Buenos Aires).\nFormato: 5 encuentros presenciales y fichas de trabajo.',
  PERU_FORMATION_REFERENTS_ITINERARY_TITLE:
    'Itinerario de formación de referentes de la comunicación',
  PERU_FORMATION_REFERENTS_ITINERARY_DESCRIPTION:
    'Cáritas Patagonia Comahue (Argentina).\nProceso anual de capacitación semi presencial (2010) con acompañamiento a distancia, para referentes voluntarios de la comunicación institucional en la Región Patagonia Comahue.',
  PERU_FORMATION_VOLUNTARIES_ITINERARY_TITLE:
    'Itinerario de formación de voluntariado social',
  PERU_FORMATION_VOLUNTARIES_ITINERARY_DESCRIPTION:
    'Cáritas Zárate Campana (Argentina).\nItinerarios de capacitación presencial (2003–2005) para voluntariado social de las Cáritas locales.',
});
