import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import Loading from '~components/Spinner/components';

import { DEFAULT_MAX_DURATION } from './constants';

function CustomSuspense({ maxDuration, fallback, children }) {
  return (
    <Suspense maxDuration={maxDuration} fallback={fallback}>
      {children}
    </Suspense>
  );
}

CustomSuspense.defaultProps = {
  fallback: Loading,
  maxDuration: DEFAULT_MAX_DURATION,
};

CustomSuspense.propTypes = {
  fallback: PropTypes.element,
  maxDuration: PropTypes.number,
  children: PropTypes.node,
};

export default CustomSuspense;
