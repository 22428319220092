import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

import logo from '~assets/components/Topbar/logo.png';
import UnderlineButton from '~components/UnderlineButton';
import Routes from '~constants/routes';
import { SECTIONS } from '~constants/sections';

import styles from './styles.module.scss';

function Topbar() {
  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <Link to={Routes.HOME}>
          <img className={styles.logo} src={logo} />
        </Link>
        <div className={styles.optionsContainer}>
          {SECTIONS.map(({ id, text, color, redirectUrl }) => (
            <div key={id} className={styles.options}>
              <UnderlineButton
                text={i18next.t(text)}
                redirectUrl={redirectUrl}
                underlineColor={color}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Topbar;
