import i18next from 'i18next';

i18next.addResources('es', 'CONSULTING', {
  CONSULTING: 'Asesorías',
  CONSULTING_INFO:
    'Asesoramos el diagnóstico, análisis y proyección de propuestas vinculadas con:',
  SOCIAL_COMMUNITY_PROJECTS: 'Proyectos Socio Comunitarios',
  SOCIAL_COMMUNITY_PROJECTS_DESCRIPTION: `
  Proyectos que buscan desplegar acciones con fuerte anclaje territorial para el desarrollo y/o
  la organización comunitaria, el fortalecimiento de capacidades y el desarrollo humano integral.
  `,
  SOCIAL_PASTORAL_PROJECTS: 'Proyectos Socio Pastorales',
  SOCIAL_PASTORAL_PROJECTS_DESCRIPTION: `
  Iniciativas que en distintos planos (locales, diocesanos, nacionales) buscan dar un nuevo impulso pastoral,
  desde el renovado proyecto pastoral que impulsa el Papa Francisco y sus aplicaciones concretas para desaprender 
  prácticas e imaginar nuevos intentos.
  `,
  EDUCATIVE_PASTORAL_PROJECTS: 'Proyectos Educativo Pastorales',
  EDUCATIVE_PASTORAL_PROJECTS_DESCRIPTION: `
  Planificación de la clave pastoral de la escuela o iniciativas específicas para sectores o aspectos del proyecto
  global. Asesoramiento en diagnóstico, capacitación y proceso de mejora.
  `,
});

i18next.addResources('en', 'CONSULTING', {
  CONSULTING: 'Consulting',
  CONSULTING_INFO:
    'Asesoramos el diagnóstico, análisis y proyección de propuestas vinculadas con:',
  SOCIAL_COMMUNITY_PROJECTS: 'Social Community Projects',
  SOCIAL_COMMUNITY_PROJECTS_DESCRIPTION: `
  Proyectos que buscan desplegar acciones con fuerte anclaje territorial para el desarrollo y/o
  la organización comunitaria, el fortalecimiento de capacidades y el desarrollo humano integral.
  `,
  SOCIAL_PASTORAL_PROJECTS: 'Social Pastoral Projects',
  SOCIAL_PASTORAL_PROJECTS_DESCRIPTION: `
  Iniciativas que en distintos planos (locales, diocesanos, nacionales) buscan dar un nuevo impulso pastoral,
  desde el renovado proyecto pastoral que impulsa el Papa Francisco y sus aplicaciones concretas para desaprender 
  prácticas e imaginar nuevos intentos.
  `,
  EDUCATIVE_PASTORAL_PROJECTS: 'Educative Pastoral Projects',
  EDUCATIVE_PASTORAL_PROJECTS_DESCRIPTION: `
  Planificación de la clave pastoral de la escuela o iniciativas específicas para sectores o aspectos del proyecto
  global. Asesoramiento en diagnóstico, capacitación y proceso de mejora.
  `,
});
