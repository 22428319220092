import i18next from 'i18next';

i18next.addResources('es', 'WORKS_EDITIONS', {
  FEST_MAP_BOOK_TITLE: 'Mapas para la Fiesta.',
  FEST_MAP_BOOK_DESCRIPTION:
    'Nueva edición del libro “Mapas para la Fiesta” (2011 – en proceso).\nPara: Edición propia.',
  ORGANIC_PASTORAL_YOUTH_BOOK_TITLE: 'Hacia una Pastoral Juvenil Orgánica',
  ORGANIC_PASTORAL_YOUTH_BOOK_DESCRIPTION:
    'Edición del texto con aportes para la reflexión de grupos de jóvenes.\nFormato: medio oficio, 1/1. Impreso en la Argentina.\nPara: Equipo de Pastoral Juvenil Diocesano de Zárate–Campana.',
  BUILDING_PATHS_BOOK_TITLE: 'Haciendo caminos (en proceso).',
});

i18next.addResources('en', 'WORKS_EDITIONS', {
  FEST_MAP_BOOK_TITLE: 'Mapas para la Fiesta.',
  FEST_MAP_BOOK_DESCRIPTION:
    'Nueva edición del libro “Mapas para la Fiesta” (2011 – en proceso).\nPara: Edición propia.',
  ORGANIC_PASTORAL_YOUTH_BOOK_TITLE: 'Hacia una Pastoral Juvenil Orgánica',
  ORGANIC_PASTORAL_YOUTH_BOOK_DESCRIPTION:
    'Edición del texto con aportes para la reflexión de grupos de jóvenes.\nFormato: medio oficio, 1/1. Impreso en la Argentina.\nPara: Equipo de Pastoral Juvenil Diocesano de Zárate–Campana.',
  BUILDING_PATHS_BOOK_TITLE: 'Haciendo caminos (en proceso).',
});
