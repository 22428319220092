import i18next from 'i18next';

i18next.addResources('es', 'ABOUT_US', {
  PROFESIONAL_GROUP:
    'Somos un grupo de profesionales de distintas disciplinas (educativas, sociales, pastorales, editoriales), que nos encontramos en un mismo deseo de ofrecer aportes de calidad que agreguen valor a proyectos y procesos que acompañamos.',
  INITIATIVES:
    'Concretamos esta iniciativa en los asesoramientos, mentoreos y consultorías; en la elaboración de propuestas formativas; en la generación y distribución de contenidos impresos o digitales.',
});

i18next.addResources('en', 'ABOUT_US', {
  PROFESIONAL_GROUP:
    'Somos un grupo de profesionales de distintas disciplinas (educativas, sociales, pastorales, editoriales), que nos encontramos en un mismo deseo de ofrecer aportes de calidad que agreguen valor a proyectos y procesos que acompañamos.',
  INITIATIVES:
    'Concretamos esta iniciativa en los asesoramientos, mentoreos y consultorías; en la elaboración de propuestas formativas; en la generación y distribución de contenidos impresos o digitales.',
});
