import i18next from 'i18next';

i18next.addResources('es', 'PUBLICATIONS', {
  EDUCATIONAL_CONTENTS: 'Contenidos educativos',
  EDUCATIONAL_CONTENTS_DESCRIPTION: `
  Asesoramos o llevamos adelante la elaboración de recursos (cartillas, cuadernillos, esquemas de cursos y talleres, 
  subsidios, etc.) para utilizarlos como apoyatura de la formación, de acuerdo con los contenidos por transmitir y la
  realidad de los destinatarios.
  `,
  DIGITAL_CONTENTS: 'Contenidos digitales',
  DIGITAL_CONTENTS_DESCRIPTION: `
  En el contexto de la multiplicación de instancias y modalidades virtuales, facilitamos la adaptación de contenidos
  a formatos y entornos digitales, para su mejor comunicación.
  `,
  EDITIONS: 'Ediciones para publicación',
  EDITIONS_DESCRIPTION: `
  Realizamos el proceso de trabajo completo sobre un texto para su publicación impresa: corrección de estilo y
  ortotipográfica, diseño gráfico, maquetación, envío a imprenta y trámites necesarios para el registro de los 
  derechos de autor y catalogación (ISBN).
  `,
  INSTITUTIONAL_PUBLICATIONS: 'Publicaciones institucionales',
  INSTITUTIONAL_PUBLICATIONS_DESCRIPTION: `
  Diseño y diagramación de distintos tipos de publicaciones e instrumentos institucionales, por ejemplo: memorias
  institucionales e informes, documentos técnicos, procedimientos y manuales, publicaciones periódicas, folletos y
  cartillas.
  `,
});

i18next.addResources('en', 'PUBLICATIONS', {
  EDUCATIONAL_CONTENTS: 'Educational contents',
  EDUCATIONAL_CONTENTS_DESCRIPTION: `
  Asesoramos o llevamos adelante la elaboración de recursos (cartillas, cuadernillos, esquemas de cursos y talleres, 
  subsidios, etc.) para utilizarlos como apoyatura de la formación, de acuerdo con los contenidos por transmitir y la
  realidad de los destinatarios.
  `,
  DIGITAL_CONTENTS: 'Digital contents',
  DIGITAL_CONTENTS_DESCRIPTION: `
  En el contexto de la multiplicación de instancias y modalidades virtuales, facilitamos la adaptación de contenidos
  a formatos y entornos digitales, para su mejor comunicación.
  `,
  EDITIONS: 'Editions',
  EDITIONS_DESCRIPTION: `
  Realizamos el proceso de trabajo completo sobre un texto para su publicación impresa: corrección de estilo y
  ortotipografía, diseño gráfico, maquetación, envío a imprenta y trámites necesarios para el registro de los 
  derechos de autor y catalogación (ISBN).
  `,
  INSTITUTIONAL_PUBLICATIONS: 'Institutional publications',
  INSTITUTIONAL_PUBLICATIONS_DESCRIPTION: `
  Diseño y diagramación de distintos tipos de publicaciones e instrumentos institucionales, por ejemplo: memorias
  institucionales e informes, documentos técnicos, procedimientos y manuales, publicaciones periódicas, folletos y
  cartillas.
  `,
});
