import i18next from 'i18next';

i18next.addResources('es', 'EDUCATION_TRAINING', {
  EDUCATION_TRAINING: 'Formación',
  EDUCATION_TRAINING_INFO: `
  En el contexto de cambios profundos en la comprensión y el abordaje de distintas problemáticas y enfoques, la
  formación aparece como un factor clave para hacer posibles las mejores condiciones que permitan ir adelante.
  `,
  FORMATION_PROCESS_DESIGN: 'Diseño de procesos formativos',
  FORMATION_PROCESS_DESIGN_DESCRIPTION: `
  Diseño pedagógico y didáctico de itinerarios formativos y de los recursos y herramientas complementarias para la
  implementación. Voluntariado, animaciones socio-culturales, agentes pastorales, monitoreo de proyectos.
  `,
  THEMATIC_MODULES: 'Módulos temáticos',
  THEMATIC_MODULES_DESCRIPTION: `
  Respuestas a necesidades puntuales en sus contenidos para momentos particulares de un grupo o comunidad de trabajo
  para potenciar sus prácticas, enriquecer su enfoque o actualizar el marco conceptual que los encuadra.
  `,
  COURSES: 'Cursos',
  COURSES_DESCRIPTION: `
  Iniciativas que en distintos planos (locales, diocesanos, nacionales) buscan dar un nuevo impulso pastoral, desde
  el renovado proyecto pastoral que impulsa el Papa Francisco y sus aplicaciones concretas paraa desaprender prácticas
  e imaginar nuevos intentos
  `,
});

i18next.addResources('en', 'EDUCATION_TRAINING', {
  EDUCATION_TRAINING: 'Education Training',
  EDUCATION_TRAINING_INFO: `
  En el contexto de cambios profundos en la comprensión y el abordaje de distintas problemáticas y enfoques, la
  formación aparece como un factor clave para hacer posibles las mejores condiciones que permitan ir adelante.
  `,
  FORMATION_PROCESS_DESIGN: 'Formation process design',
  FORMATION_PROCESS_DESIGN_DESCRIPTION: `
  Diseño pedagógico y didáctico de itinerarios formativos y de los recursos y herramientas complementarias para la
  implementación.\n
  Voluntariado, animaciones socio-culturales, agentes pastorales, monitoreo de proyectos.
  `,
  THEMATIC_MODULES: 'Thematic modules',
  THEMATIC_MODULES_DESCRIPTION: `
  Respuestas a necesidades puntuales en sus contenidos para momentos particulares de un grupo o comunidad de trabajo
  para potenciar sus prácticas, enriquecer su enfoque o actualizar el marco conceptual que los encuadra.
  `,
  COURSES: 'Courses',
  COURSES_DESCRIPTION: `
  Iniciativas que en distintos planos (locales, diocesanos, nacionales) buscan dar un nuevo impulso pastoral, desde
  el renovado proyecto pastoral que impulsa el Papa Francisco y sus aplicaciones concretas paraa desaprender prácticas
  e imaginar nuevos intentos
  `,
});
