import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonLink = styled(Link)`
  ${(props) =>
    props.underlineColor &&
    css`
      display: inline-block;
      border-bottom: 3px solid ${props.underlineColor};
      color: black;
      font-family: Gotham;
      padding: 3px;
      text-decoration: none;

      &:hover {
        background-color: ${props.underlineColor};
        color: black;
      }

      @media screen and (max-width: 660px) {
        font-size: 10px;
      }
    `}
`;
