import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Suspense from '~components/Suspense';
import Topbar from '~components/Topbar';
import Footer from '~components/Footer';
import { ROUTES } from '~components/Routes/constants';

import styles from './styles.module.scss';

function AppRoutes() {
  return (
    <Router>
      <div className={styles.container}>
        <Topbar />
        <Suspense>
          <Switch>
            {ROUTES.map((route) => (
              <Route
                key={route.path}
                exact
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
}

export default AppRoutes;
