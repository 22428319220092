import i18next from 'i18next';

i18next.addResources('es', 'CONTACT', {
  CONTACT: 'Contacto',
  CONTACT_GENERAL:
    'Para sumarte a nuestra lista de difusión, conocer novedades y recibir propuestas de cursos y talleres:\n\ninfo@equiposurcos.com.ar',
  CONTACT_COORDINATION:
    'Para contactarte con nosotros, coordinar entrevistas y solicitar presupuestos de servicios, escribinos a:\n\ncoordinacion@equiposurcos.com.ar',
});

i18next.addResources('en', 'CONTACT', {
  CONTACT: 'Contacto',
  CONTACT_GENERAL:
    'Para sumarte a nuestra lista de difusión, conocer novedades y recibir propuestas de cursos y talleres:\n\ninfo@equiposurcos.com.ar',
  CONTACT_COORDINATION:
    'Para contactarte con nosotros, coordinar entrevistas y solicitar presupuestos de servicios, escribinos a:\n\ncoordinacion@equiposurcos.com.ar',
});
