import i18next from 'i18next';

i18next.addResources('es', 'SECTION', {
  US: 'Nosotros',
  CONSULTING: 'Asesorías',
  EDUCATION_TRAINING: 'Formación',
  PUBLICATIONS: 'Publicaciones',
  WORKS: 'Trabajos',
  CONTACT: 'Contacto',
});

i18next.addResources('en', 'SECTION', {
  US: 'US',
  CONSULTING: 'Consulting',
  EDUCATION_TRAINING: 'Education',
  PUBLICATIONS: 'Publications',
  WORKS: 'Works',
  CONTACT: 'Contact',
});
