import i18next from 'i18next';

i18next.addResources('es', 'FRIENDLY_LINKS', {
  FRIENDLY_LINKS_DESC_1:
    'Vamos conectando nuestras búsquedas con la de muchos otros grupos o colectivos que sueñan con un mundo justo, fraterno, solidario. Entre muchos otros:',
});

i18next.addResources('en', 'FRIENDLY_LINKS', {
  FRIENDLY_LINKS_DESC_1:
    'Vamos conectando nuestras búsquedas con la de muchos otros grupos o colectivos que sueñan con un mundo justo, fraterno, solidario. Entre muchos otros:',
});
