import { lazy } from 'react';

import Routes from '~constants/routes';

const Home = lazy(() => import('~views/Home'));
const Us = lazy(() => import('~views/Us'));
const Consulting = lazy(() => import('~views/Consulting'));
const EducationTraining = lazy(() => import('~views/EducationTraining'));
const Publications = lazy(() => import('~views/Publications'));
const Works = lazy(() => import('~views/Works'));
const Contact = lazy(() => import('~views/Contact'));

export const ROUTES = [
  {
    path: Routes.HOME,
    component: Home,
    isPrivateRoute: false,
  },
  {
    path: Routes.US,
    component: Us,
    isPrivateRoute: false,
  },
  {
    path: Routes.CONSULTING,
    component: Consulting,
    isPrivateRoute: false,
  },
  {
    path: Routes.EDUCATION_TRAINING,
    component: EducationTraining,
    isPrivateRoute: false,
  },
  {
    path: Routes.PUBLICATIONS,
    component: Publications,
    isPrivateRoute: false,
  },
  {
    path: Routes.WORKS,
    component: Works,
    isPrivateRoute: false,
  },
  {
    path: Routes.CONTACT,
    component: Contact,
    isPrivateRoute: false,
  },
];
