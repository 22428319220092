import i18next from 'i18next';

i18next.addResources('es', 'WORKS', {
  WORKS: 'Trabajos',
  INSTITUTIONAL_PUBLICATIONS: 'Publicaciones institucionales',
  ITINERARIES_FORMATION_RESOURCES: 'Itinerarios y recursos formativos',
  EDITIONS: 'Ediciones',
});

i18next.addResources('en', 'WORKS', {
  WORKS: 'Works',
  INSTITUTIONAL_PUBLICATIONS: 'Institutional publications',
  ITINERARIES_FORMATION_RESOURCES: 'Itineraries and formation resources',
  EDITIONS: 'Editions',
});
