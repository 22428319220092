import React from 'react';
import i18next from 'i18next';

import styles from './styles.module.scss';

function Footer() {
  return (
    <div className={styles.footer}>
      <p>{i18next.t('FOOTER:TEXT')}</p>
    </div>
  );
}

export default Footer;
