export const COLORS = {
  gray: '#898989',
  saltBox: '#6D6C6F',
  hockeyPokey: '#D3B526',
  oldGold: '#D3B63A',
  turmeric: '#D2B84D',
  tacha: '#D2B75E',
  putty: '#E9D68C',
  deepCerulean: '#0776B0',
  curiousBlue: '#2188C0',
  shakespeare: '#3F9ED2',
  pictonBlue: '#5FB2E3',
  cornflower: '#95C6EB',
  appleBlossom: '#B13F3F',
  matrix: '#A45E4D',
  santaFe: '#B87562',
  contessa: '#C3826F',
  myPink: '#CE917D',
  roseFog: '#E4C1B3',
  persianRed: '#C22F32',
  flamingo: '#EE423B',
  flamingoLight: '#F05744',
  burntSienna: '#F27056',
  roseBud: '#F9B69E',
  red: '#EC0E07',
  sunglow: '#FFC82A',
  cerulean: '#0091D8',
  atlantis: '#A7CE44',
  mantis: '#81B763',
  deYork: '#7DB883',
  darkSeaGreen: '#92C091',
  royalHeath: '#A43490',
  gumLeaf: '#ACD2B8',
  plum: '#8A2E7A',
  trendyPink: '#926293',
};
