import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).init({
  initImmediate: false,
});

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

requireAll(require.context('../app', true, /i18n\.js$/));
requireAll(require.context('../constants', true, /i18n\.js$/));
