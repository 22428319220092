import Routes from '~constants/routes';
import { COLORS } from '~constants/colors';

export const SECTIONS = [
  {
    id: 'us',
    text: 'SECTION:US',
    color: COLORS.red,
    redirectUrl: Routes.US,
  },
  {
    id: 'consulting',
    text: 'SECTION:CONSULTING',
    color: COLORS.sunglow,
    redirectUrl: Routes.CONSULTING,
  },
  {
    id: 'education_training',
    text: 'SECTION:EDUCATION_TRAINING',
    color: COLORS.cerulean,
    redirectUrl: Routes.EDUCATION_TRAINING,
  },
  {
    id: 'publications',
    text: 'SECTION:PUBLICATIONS',
    color: COLORS.matrix,
    redirectUrl: Routes.PUBLICATIONS,
  },
  {
    id: 'works',
    text: 'SECTION:WORKS',
    color: COLORS.atlantis,
    redirectUrl: Routes.WORKS,
  },
  {
    id: 'contact',
    text: 'SECTION:CONTACT',
    color: COLORS.royalHeath,
    redirectUrl: Routes.CONTACT,
  },
];
