import i18next from 'i18next';

i18next.addResources('es', 'HOME', {
  MAIN_TITLE: 'Acompañar, aportar, potenciar',
  SUBTITLE:
    'Al servicio de equipos, acciones y procesos en ámbitos educativos, sociales y pastorales',
  DESCRIPTION: `
  Nuestro equipo asesora y acompaña proyectos, acciones y procesos.
  Aportamos perspectivas, ideas y concreciones que faciliten nuevos espacios, inspiren innovaciones y amplíen horizontes:
  abrir espacios para intentar nuevas prácticas con mayores impactos.
  `,
});

i18next.addResources('en', 'HOME', {
  MAIN_TITLE: 'Acompañar, aportar, potenciar',
  SUBTITLE:
    'Al servicio de equipos, acciones y procesos en ámbitos educativos, sociales y pastorales',
  DESCRIPTION: `
  Nuestro equipo asesora y acompaña proyectos, acciones y procesos.
  Aportamos perspectivas, ideas y concreciones que faciliten nuevos espacios, inspiren innovaciones y amplíen horizontes:
  abrir espacios para intentar nuevas prácticas con mayores impactos.
  `,
});
