import React from 'react';
import { string } from 'prop-types';

import styles from './styles.module.scss';
import { ButtonLink } from './styles';

function UnderlineButton({ text, redirectUrl, underlineColor }) {
  return (
    <button className={styles.buttonContainer}>
      <ButtonLink to={redirectUrl} underlineColor={underlineColor}>
        {text}
      </ButtonLink>
    </button>
  );
}

UnderlineButton.propTypes = {
  text: string,
  redirectUrl: string,
  underlineColor: string,
};

export default UnderlineButton;
