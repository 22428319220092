import i18next from 'i18next';

i18next.addResources('es', 'OUR_MISSION', {
  OUR_MISSION_DESC_1:
    'Este tiempo, de un modo particular, requiere potenciar todo aquello que ayude a despertar mayor conciencia de la necesidad de hacer cambios profundos en nuestros modos de vivir, conocer y transformar la realidad. Los grandes desafíos que evidenciaron con más claridad la pandemia y la post pandemia requieren propuestas claras que abran camino a experiencias de solidaridad, justicia, desarrollo y organización comunitaria…',
  OUR_MISSION_DESC_2:
    'Diseñar propuestas formativas, generar contenidos, acompañar procesos en organizaciones sociales y eclesiales, en comunidades educativas —entre otros— son modos concretos de ser parte de ese cambio que nuestra época reclama.',
});

i18next.addResources('en', 'OUR_MISSION', {
  OUR_MISSION_DESC_1:
    'Este tiempo, de un modo particular, requiere potenciar todo aquello que ayude a despertar mayor conciencia de la necesidad de hacer cambios profundos en nuestros modos de vivir, conocer y transformar la realidad. Los grandes desafíos que evidenciaron con más claridad la pandemia y la post pandemia requieren propuestas claras que abran camino a experiencias de solidaridad, justicia, desarrollo y organización comunitaria…',
  OUR_MISSION_DESC_2:
    'Diseñar propuestas formativas, generar contenidos, acompañar procesos en organizaciones sociales y eclesiales, en comunidades educativas —entre otros— son modos concretos de ser parte de ese cambio que nuestra época reclama.',
});
