import i18next from 'i18next';

i18next.addResources('es', 'US', {
  US: 'Nosotros',
  ABOUT_US: 'Quiénes somos',
  OUR_VISION: 'Nuestra visión',
  FRIENDLY_LINKS: 'Enlaces amigos',
});

i18next.addResources('en', 'US', {
  US: 'Us',
  ABOUT_US: 'About us',
  OUR_VISION: 'Our vision',
  FRIENDLY_LINKS: 'Friendly links',
});
