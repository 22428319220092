const Routes = {
  HOME: '/',
  US: '/nosotros',
  CONSULTING: '/accesorias',
  EDUCATION_TRAINING: '/formacion',
  PUBLICATIONS: '/publicaciones',
  WORKS: '/trabajos',
  CONTACT: '/contacto',
};

export default Routes;
