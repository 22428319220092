import i18next from 'i18next';

const currentTime = new Date();
const copyrightTerm = `2020-${currentTime.getFullYear()}`;

i18next.addResources('es', 'FOOTER', {
  TEXT: `Todos los derechos reservados - Equipo Surcos © ${copyrightTerm} - Ciudad Autónoma de Buenos Aires | Argentina`,
});

i18next.addResources('en', 'FOOTER', {
  TEXT: `All rights reserved - Surcos Team © ${copyrightTerm} - Autonomous City of Buenos Aires | Argentina`,
});
