import i18next from 'i18next';

i18next.addResources('es', 'WORKS_INSTITUTIONAL_PUBLICATIONS', {
  CARITAS_TITLE: 'Cáritas',
  CARITAS_MEMORIA:
    '<b>Memoria 2007</b> - Encuentro nacional de directores/as y viceprecidentes de Cáritas diocesanas.',
  CARITAS_REVISION: `Revision y corrección de textos, diseño gráfico e impresión de la memoria del Encuentro.
    <ul>
        <li>Formato A4 1/1, tapa color, anillado, de 57 páginas. Impreso en Argentina.</li>
        <li>Para: Equipo de desarrollo Institucional de Cáritas Nacional (Argentina)</li>
    </ul>`,
  CARITAS_PASTORAL: `"<b>Cáritas y Pastoral Social:</b> tejido reflexiones y metodologías de equidad entre mujeres y hombres" 
    - Documento final. Cáritas Latinoamerica. SELACC.`,
  MATT_LAMB_TITLE: 'Matt Lamb',
  MATT_LAMB_BARCELONA_TITLE: 'Matt Lamb - Barcelona',
  MATT_LAMB_BARCELONA_DESCRIPTION: `Diseño y puesta en imprenta del catálogo para las presentaciones internacionales de Matt Lamb en el Museo
    Diocesano de Barcelona, en el Monumento de la Pia Almoina y en el Real ciculo Artistico de Barcelona.
    <br />
    Formato: 20x40 cm, encuadernacion de tapa dura color 4/4 e
    interior color 4/4, de 144 páginas. Impreso en Barcelona, España.`,
  MATT_LAMB_SCULPTURES_TITLE:
    'Matt Lamb, Sculptures & Millenium - Fundació Els Arcs',
  MATT_LAMB_SCULPTURES_ITEM1: `Diseño y puesta en imprenta del Libro Recordatorio para la
    presentación internacional de Matt Lamb en Barcelona, en la
    Fundación Los Arcos.`,
  MATT_LAMB_SCULPTURES_ITEM2: `Formato: 21 x 22 cm, encuadernación en tapa dura color 4/4 e
    interior color 4/4, de 60 páginas. Impreso en Barcelona,
    España.`,
  MATT_LAMB_BARCELONA_COLLECTION_TITLE: 'The Barcelona Collection – Matt Lamb',
  MATT_LAMB_BARCELONA_COLLECTION_ITEM1: `Diseño y puesta en imprenta del catálogo para la exposición
    internacional de Matt Lamb en Barcelona.`,
  MATT_LAMB_BARCELONA_COLLECTION_ITEM2: `Formato: 22 x 22 cm, encuadernación en tapa dura color 4/4 e
    interior color 4/4, de 60 páginas. Impreso en Italia. Para: Europa Museum Schengen (Luxemburgo).`,
});

i18next.addResources('en', 'WORKS_INSTITUTIONAL_PUBLICATIONS', {
  CARITAS_TITLE: 'Cáritas',
  CARITAS_MEMORIA:
    '<b>Memoria 2007</b> - Encuentro nacional de directores/as y viceprecidentes de Cáritas diocesanas.',
  CARITAS_REVISION: `Revision y corrección de textos, diseño gráfico e impresión de la memoria del Encuentro.
      <ul>
          <li>Formato A4 1/1, tapa color, anillado, de 57 páginas. Impreso en Argentina.</li>
          <li>Para: Equipo de desarrollo Institucional de Cáritas Nacional (Argentina)</li>
      </ul>`,
  CARITAS_PASTORAL: `"<b>Cáritas y Pastoral Social:</b> tejido reflexiones y metodologías de equidad entre mujeres y hombres" 
      - Documento final. Cáritas Latinoamerica. SELACC.`,
  MATT_LAMB_TITLE: 'Matt Lamb',
  MATT_LAMB_BARCELONA_TITLE: 'Matt Lamb - Barcelona',
  MATT_LAMB_BARCELONA_DESCRIPTION: `Diseño y puesta en imprenta del catálogo para las presentaciones internacionales de Matt Lamb en el Museo
      Diocesano de Barcelona, en el Monumento de la Pia Almoina y en el Real ciculo Artistico de Barcelona.
      <br />
      Formato: 20x40 cm, encuadernacion de tapa dura color 4/4 e
      interior color 4/4, de 144 páginas. Impreso en Barcelona, España.`,
  MATT_LAMB_SCULPTURES_TITLE:
    'Matt Lamb, Sculptures & Millenium - Fundació Els Arcs',
  MATT_LAMB_SCULPTURES_ITEM1: `Diseño y puesta en imprenta del Libro Recordatorio para la
      presentación internacional de Matt Lamb en Barcelona, en la
      Fundación Los Arcos.`,
  MATT_LAMB_SCULPTURES_ITEM2: `Formato: 21 x 22 cm, encuadernación en tapa dura color 4/4 e
      interior color 4/4, de 60 páginas. Impreso en Barcelona,
      España.`,
  MATT_LAMB_BARCELONA_COLLECTION_TITLE: 'The Barcelona Collection – Matt Lamb',
  MATT_LAMB_BARCELONA_COLLECTION_ITEM1: `Diseño y puesta en imprenta del catálogo para la exposición
      internacional de Matt Lamb en Barcelona.`,
  MATT_LAMB_BARCELONA_COLLECTION_ITEM2: `Formato: 22 x 22 cm, encuadernación en tapa dura color 4/4 e
      interior color 4/4, de 60 páginas. Impreso en Italia. Para: Europa Museum Schengen (Luxemburgo).`,
});
